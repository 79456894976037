import { render } from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./App";
import Plot from "./routes/plot";
import Plot3d from "./routes/plot3d";
import Layout from "./components/layout/Layout";
import classes from "./index.css";

const rootElement = document.getElementById("root");
render(
  <BrowserRouter>
    <Layout>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="plot" element={<Plot />} />
        <Route path="plot3d" element={<Plot3d />} />
      </Routes>
    </Layout>
  </BrowserRouter>,
  rootElement
);
