import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-luxon";
import ChartStreaming from "chartjs-plugin-streaming";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import volume from "plotly.js/lib/volume";

// const client = new W3CWebSocket("ws://127.0.0.1:7776");
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
ChartJS.register(ChartStreaming);

function Plot({realdata}) {

  // setInterval(
    // function lastvaluecheck(){
    //   //lastvalue=realdata.volume
    //   if (client.readyState === WebSocket.CLOSED) {
    //     setrealdata({
    //       volume:undefined,
    //       temperature:undefined,
    //       surfacearea:undefined,
    //       rotationspeed:undefined
    //     })
    //     console.log("connection is closed")
    //   }
    // },4000);

  const labels = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
  ];
  const [data, setData] = useState({
    labels,
    datasets: [
      {
        label: "Volume(mm³)",
        data: [140, 180, 150, 170, 169, 180, 173],
        borderColor: "rgb(100, 40, 132)",
        backgroundColor: "rgba(100, 40, 132, 0.5)",
      },
      {
        label: "Temperature(C)",
        data: [80, 40, 50, 75, 69, 58, 73],
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Surface Area(mm²)",
        data: [10, 40, 50, 35, 69, 8, 93],
        borderColor: "rgb(255, 128, 0)",
        backgroundColor: "rgba(255, 128, 0, 0.5)",
      },
      {
        label: "Rotation Speed(RPM)",
        data: [10, 40, 50, 35, 69, 8, 93],
        borderColor: "rgb(255, 0, 0)",
        backgroundColor: "rgba(255, 0, 0, 0.5)",
      },
    ],
  });
  const options = {};

  return (
    <>
    
      <Line
        options={{
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            zoom: {
              // Assume x axis has the realtime scale
              pan: {
                enabled: true,        // Enable panning
                mode: 'x'             // Allow panning in the x direction
              },
              zoom: {
                pinch: {
                  enabled: true       // Enable pinch zooming
                },
                wheel: {
                  enabled: true       // Enable wheel zooming
                },
                mode: 'x'             // Allow zooming in the x direction
              },
              limits: {
                x: {
                  minDelay: null,     // Min value of the delay option
                  maxDelay: null,     // Max value of the delay option
                  minDuration: null,  // Min value of the duration option
                  maxDuration: null   // Max value of the duration option
                }
              }
            },
            streaming: {
              duration: 50000,
              refresh: 20000,
            },

            legend: {
              position: "top",
            },
            title: {
              display: true,
              text: "2D Graph",
            },
          },
          scales: {
            x: {
              type: "realtime",
              realtime: {
                refresh: 500,

                onRefresh: (chart) => {
                  chart.data.datasets.forEach((dataset) => {
                    // console.log(dataset);
                    if (dataset["label"] === "Volume(mm³)") {
                      dataset.data.push({
                        x: Date.now(),
                        y: realdata.volume,
                      });
                    } else if (dataset["label"] === "Temperature(C)") {
                      dataset.data.push({
                        x: Date.now(),
                        y: realdata.temperature,
                      });
                    } else if (dataset["label"] === "Surface Area(mm²)"){
                      dataset.data.push({
                        x: Date.now(),
                        y: realdata.surfacearea,
                      });
                    }else if (dataset["label"] === "Rotation Speed(RPM)"){
                      dataset.data.push({
                        x: Date.now(),
                        y: realdata.rotationspeed,
                      });
                    } else {


                    }
                  });
                },
              },
            },
          },
        }}
        data={data}
      />
    </>
  );
}
export default Plot;