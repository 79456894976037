import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Plot3d from "../components/plots/plot3d";

export default function plot3d() {
  return (
    <section>
      <Card>
        <Col>
          <Card
            style={{
              height: "380px",
              ["box-shadow"]:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
          >
            <Card.Body>
              <Plot3d />
            </Card.Body>
          </Card>
        </Col>
      </Card>
    </section>
  );
}
