import { Link, useNavigate } from "react-router-dom";
import Card from './components/ui/Card';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FcOk } from "react-icons/fc";
import { FcHighPriority } from "react-icons/fc";
import React, { Component } from 'react';


export default function App() {

  let navigate = useNavigate();
  const itemsScanned = [693,421];

  function handleClick(name) {
    navigate('/plot')
  }
  return (
    <div>  
      <Card>
        <h1>Workstation 1</h1>
        <h3>Server Status: <FcOk/></h3>
        <h3>Objects Scanned:{" " + itemsScanned[0]}</h3>
        <Button variant="primary" onClick={handleClick}>Show Info</Button>
      </Card>

      <Card>
        <h1>Workstation 2</h1>
        <h3>Server Status: <FcHighPriority/></h3>
        <h3>Objects Scanned:{" " + itemsScanned[1]}</h3>
        <Button variant="primary" onClick={handleClick}>Show Info</Button>
      </Card>
    </div>
  );
}