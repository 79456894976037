import React,{useEffect, useState} from 'react';
import createPlotlyComponent from 'react-plotly.js/factory';
import { w3cwebsocket as W3CWebSocket } from "websocket";
const Plotly = window.Plotly;
const Plot = createPlotlyComponent(Plotly);

// const client = new W3CWebSocket('ws://127.0.0.1:7776');

function Plot3d({data3d}) {
  const [volume, setVolume] = useState([]);
  const [temperature, setTemperature] = useState([]);
  const [area, setArea] = useState([]);
  const [rotationspeed, setRotation] = useState([]);
  const [x, setX] = useState([]);
  const [y, setY] = useState([]);
  const [z, setZ] = useState([]);
  const [data, setData] = useState([
    {
      alphahull: 0,
      opacity: 1,
      color: "rgb(233,233,233)",
      type: "mesh3d",
      x: [x],
      y: [y],
      z: [z],
    },
  ]);
  // client.onopen = () => {
  //   console.log('WebSocket Client Connected 3d');
  // };
  
  // client.onmessage = async(message) => {
  //   console.log(message.data);
  //   if (typeof message.data === 'string'){
  //     if(message.data === 'Test'){
  //       return;
  //     }
        
  //     let JSONobj = JSON.parse(message.data);
  //     if (typeof(JSONobj.x) !== 'undefined' && JSONobj.x != null) {
  //       console.log("this should be not undefined");
  //       console.log(JSONobj.x + "this is x");
  //       setX(() => [...JSONobj.x]);
  //       setY(() => [...JSONobj.y]);
  //       setZ(() => [...JSONobj.z]);
     
  //     }else if(typeof(JSONobj.volume) !== 'undefined' && JSONobj.volume != null){
  //       setVolume(() => [JSONobj.volume]);  
  //       setArea(() => [JSONobj.area]);  
  //       setTemperature(() => [JSONobj.temperature]);  
  //       setRotation(() => [JSONobj.rotationspeed]);  
  //     }else {
  //       console.log("this is undefined pls staph");
  //       console.log(JSONobj.x + "this is x that should be undefined");
  //     }
  //     console.log (JSONobj.volume + JSONobj.area + JSONobj.temperature + JSONobj.rotationspeed + JSONobj.x)
  //   }
  // }
  
  function setnewdata(){
    if(data3d!==undefined && data3d !== null){

      let JSONobj = JSON.parse(data3d);
      if (typeof(JSONobj.x) !== 'undefined' && JSONobj.x != null) {
        console.log("this should be not undefined");
        console.log(JSONobj.x + "this is x");
        setX(() => [...JSONobj.x]);
        setY(() => [...JSONobj.y]);
        setZ(() => [...JSONobj.z]);
      
      }else if(typeof(JSONobj.volume) !== 'undefined' && JSONobj.volume != null){
        setVolume(() => [JSONobj.volume]);  
        setArea(() => [JSONobj.area]);  
        setTemperature(() => [JSONobj.temperature]);  
        setRotation(() => [JSONobj.rotationspeed]);  
      }else {
        console.log("this is undefined pls staph");
        console.log(JSONobj.x + "this is x that should be undefined");
      }
      console.log (JSONobj.volume + JSONobj.area + JSONobj.temperature + JSONobj.rotationspeed + JSONobj.x)
    }
  }
  
  function warningTemp() {
    if (temperature >= 150) {
      console.log("too hot");
      return true;
    } else {
      console.log("normal temps");
      return false;
    }
  }

  var layout = {
    autosize: true,
    width: 300,
    height: 220,
    margin: {
      l: 0,
      r: 0,
      b: 5,
      t: 5,
      pad: 0,
    },
  };

  
  useEffect(() => {
    setnewdata();
    const interval = setInterval(() => {
      setData(() => [
        {
          alphahull: 0,
          opacity: 1,
          color: "rgb(233,233,233)",
          type: "mesh3d",
          x: x,
          y: y,
          z: z,
        },
      ]);
    }, 500);
    return () => clearInterval(interval);
  }, [data, x, y, z,data3d]);

  return (
    <div>
    <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
      <Plot data={data} layout={layout} />
    </div>
    <hr></hr>
    <div style={{ ["text-align"]: "left" }}>
      <span id="paraVolume" >
        Volume: {volume}mm³
        </span>
        <br />
        <span id="paraArea">
        Surface Area: {area}mm²
        </span>
        <br />
        <span id="paraTemperature" style={warningTemp() ? {color:"red"} : {color:"black"}}>
        Temperature: {temperature}C
        </span>
        <br />
        <span id="paraRotation">
        RPM: {rotationspeed}RPM
        </span>
      
    </div>
    </div>
  );
}


export default Plot3d