import { useContext } from "react";
import { Link } from "react-router-dom";
import classes from "./MainNavigation.module.css";
import Card from "react-bootstrap/Card";

function MainNavigation() {
  return (
    <Card
      style={{
        ["box-shadow"]:
          "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
      }}
    >
      <header className={classes.header}>
        <div className={classes.logo}>
          <h3>MakeYourChoice</h3>
        </div>
        <nav>
          <ul>
            <li>
              <Link to="/">Index</Link>
            </li>
            <li>
              <Link to="/plot">Main Graph</Link>
            </li>
            <li>
              <Link to="/plot3d">3D Graph</Link>
            </li>
          </ul>
        </nav>
      </header>
    </Card>
  );
}

export default MainNavigation;
