import React from "react";
import ReactDOM from "react-dom";
import createPlotlyComponent from 'react-plotly.js/factory';
import { useState, useEffect } from "react";
import { w3cwebsocket as W3CWebSocket } from "websocket";

import { usePapaParse } from "react-papaparse";
// import { plot } from "plotly.js/lib/bar";

const Plotly = window.Plotly;
const Plot = createPlotlyComponent(Plotly);
// const client = new W3CWebSocket(`wss://39.42.158.166:7776`);
// const client = new W3CWebSocket(`ws://rtdv.herokuapp.com/`);
// const client = new W3CWebSocket(`wss://rtdv.herokuapp.com/`);

// const client = new W3CWebSocket('ws://data-visualization.bringitgo.tech/');
const client = new W3CWebSocket('wss://data-visualization.bringitgo.tech/');
// const client = new W3CWebSocket('ws://127.0.0.1:3000');
//const client = new W3CWebSocket("ws://10.130.0.199:7776");

const layout = {
  autosize: true,
  datarevision: 0,
  width: 500,
  height: 485,
  margin: {
    l: 0,
    r: 0,
    b: 0,
    t: 0,
    pad: 4,
  },
  scene: {
    camera: {
      eye: {
        x: 1.2,
        y: 1.2,
        z: 1.2,
      },
    },
    xaxis: {
      nticks: 10,
      range: [-35, 35],
    },
    yaxis: {
      nticks: 10,
      range: [-25, 25],
    },
    zaxis: {
      nticks: 10,
      range: [-20, 40],
    },
  },
};

//const revision = 0;

const plotData = [
  {
    contour: {},
    alphahull: 3,

    mode: "markers",
    marker: {
      size: 4,
      color: "rgb(200,200,200)",
      line: {
        color: "rgba(217, 217, 217, 0.0)",
        width: 0.01,
      },
      opacity: 1,
    },
    type: "scatter3d",
    x: [0],
    y: [0],
    z: [0],
    color: "rgb(220,220,220)",
    opacity: 1,
    colorscale: "Viridis",
    lighting: {
      diffuse: 1,
      ambient: 0.5,
      facenormalsepsilon: 0, // important
      vertexnormalepsilon: 0,
    },
    lightposition: {
      x: 2000,
      y: 1000,
    },
  },
];

function Plot3d() {
  const [revision, setRevision] = useState([]);
  const [volume, setVolume] = useState([]);
  const [temperature, setTemperature] = useState([]);
  const [area, setArea] = useState([]);
  const [rotationspeed, setRotation] = useState([]);

  function warningTemp() {
    if (temperature >= 150) {
      console.log("too hot");
      return true;
    } else {
      console.log("normal temps");
      return false;
    }
  }

  useEffect(() => {
    client.onopen = () => {
      console.log("WebSocket Client Connected");
    };

    client.onmessage = async (message) => {
      console.log(message.data);
      if (typeof message.data === "string") {
        if (message.data === "Test") {
          return;
        }

        let JSONobj = JSON.parse(message.data);

        if (typeof JSONobj.x !== "undefined" && JSONobj.x != null) {
          console.log(message.data + "and then this is the parse" + JSONobj.x);
          console.log("this should be not undefined");
          console.log(JSONobj.x + "this is x");

          plotData[0].x = [...JSONobj.x];
          plotData[0].y = [...JSONobj.y];
          plotData[0].z = [...JSONobj.z];

          setRevision(() => revision + 1);
          layout.datarevision += 1;
          console.log(
            layout.datarevision + "this is the current data revision"
          );
        } else if (
          typeof JSONobj.volume !== "undefined" &&
          JSONobj.volume != null
        ) {
          setVolume(() => [JSONobj.volume]);
          setArea(() => [JSONobj.area]);
          setTemperature(() => [JSONobj.temperature]);
          setRotation(() => [JSONobj.rotationspeed]);
        } else {
          console.log("this is undefined pls staph");
          console.log(JSONobj.x + "this is x that should be undefined");
        }
      }
    };
  }, []);

  return (
    <div>
      <span
        style={{
          ["font-weight"]: "650",
          color: "#666666",
          ["font-size"]: "24px",
        }}
      >
        Realtime Model Rendering
      </span>
      <hr style={{ margin: "0px" }}></hr>
      <div
        id="test"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Plot data={plotData} layout={layout} revision={revision} />
      </div>
    </div>
  );
}

export default Plot3d;
