import { useState } from "react";
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Plot from '../components/plots/plotlive';
import Plot3d from '../components/plots/new3dplot';
import Container from 'react-bootstrap/Container';
import { w3cwebsocket as W3CWebSocket } from "websocket";

// const client = new W3CWebSocket('ws://data-visualization.bringitgo.tech/');
const client = new W3CWebSocket('wss://data-visualization.bringitgo.tech/');
// const client = new W3CWebSocket('ws://127.0.0.1:3000');
// const client = new W3CWebSocket('ws://127.0.0.1:3000');
// const client = new W3CWebSocket(`wss://rtdv.herokuapp.com/`);


export default function PlotLive() {
   const [realdata, setrealdata] = useState({
      volume: 0,
      temperature: 0,
      surfacearea: 0,
      rotationspeed: 0
   })
   const [data, setdata] = useState()
   //  setInterval(
   //  function wsconnectioncheck(){
   //    if (client.readyState === WebSocket.CLOSED) {
   //      setrealdata({
   //        volume:undefined,
   //        temperature:undefined,
   //        surfacearea:undefined,
   //        rotationspeed:undefined
   //      })
   //      console.log("connection is closed")
   //    }
   //  },4000);
   client.onopen = () => {
      console.log('WebSocket Client Connected');
   };
   client.onmessage = async(message) => {
      // console.log(message.data);
      if (typeof message.data === 'string'){
         if(message.data === 'Test'){
           return;
         }
         console.log(JSON.parse(message.data))
         setdata(message.data);
         const JSONobj =JSON.parse(message.data);
         if(JSONobj.volume !=="undefined" && JSONobj.volume != null){
            setrealdata({
               volume: JSONobj.volume,
               temperature: JSONobj.temperature,
               surfacearea: JSONobj.area,
               rotationspeed: JSONobj.rotationspeed
            })
         }
         // if (typeof(JSONobj.x) !== 'undefined' && JSONobj.x != null) {
         //    console.log("this should be not undefined");
         //    console.log(JSONobj.x + "this is x");
         //    setX(() => [...JSONobj.x]);
         //    setY(() => [...JSONobj.y]);
         //    setZ(() => [...JSONobj.z]);
         
         // }else if(typeof(JSONobj.volume) !== 'undefined' && JSONobj.volume != null){
         //    setVolume(() => [JSONobj.volume]);  
         //    setArea(() => [JSONobj.area]);  
         //    setTemperature(() => [JSONobj.temperature]);  
         //    setRotation(() => [JSONobj.rotationspeed]);  
         // }else {
         //    console.log("this is undefined pls staph");
         //    console.log(JSONobj.x + "this is x that should be undefined");
         // }
          
       }
    };
   return (
      <section>
         <Container fluid>
            <Row>
               <Col md="8" style={{["margin-bottom"]:'10px'}}>
               <Card style={{ height:'380px', margin: '0px', ["box-shadow"]: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}>
                  <Card.Body>
                     
                     <Plot realdata={realdata}/>
                  </Card.Body>
               </Card>
               </Col>

               <Col md="4">
               <Card style={{ height:'380px', ["box-shadow"]: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}>
                  <Card.Body>
                    
                     <Plot3d data3d={data}/>
                     
                     
                  </Card.Body>
               </Card>
               </Col>
            </Row>
         </Container>

      </section>
   );
}